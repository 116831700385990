import React from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import CookieConsent from "react-cookie-consent"
import AppBar from "./app-bar"
import "./layout.css"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyright: {
      fontSize: "0.6rem",
    },
    divider: {
      color: "white",
      marginTop: "1rem",
      height: "0.1rem",
      backgroundColor: "black",
    },
  })
)

const Layout = ({ children }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query SiteConsentCookieQuery {
      site {
        siteMetadata {
          cookieConsent
        }
      }
    }
  `)

  return (
    <>
      <AppBar />
      <main>{children}</main>
      <footer>
        <Divider className={classes.divider} />
        <Typography
          variant="caption"
          display="block"
          className={classes.copyright}
        >
          © {new Date().getFullYear()}, Code Green LLC
        </Typography>
      </footer>
      <CookieConsent>{data.site.siteMetadata.cookieConsent}</CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
